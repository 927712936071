import * as ApiResources from 'ApiResource'
import RouteName from '@/js/metadata/RouteName.js'

export default class ApiOperation {
  _apiResource = null
  _route = null
  _method = null
  constructor ({ apiResource, route, method }) {
    this._apiResource = ApiResources[apiResource]
    this._route = route
    this._method = method
  }

  get apiResource () {
    return this._apiResource
  }

  get method () {
    return this._method
  }

  get defaults () {
    return {
      _format: 'json'
    }
  }

  get route () {
    return new RouteName({ name: this._route })
  }
}
