import Router from 'fos-router'

export default class ApiResource {
  _name = null
  _operations = null
  _iri = null
  constructor ({ name, operations, iri }) {
    this._name = name
    this._operations = operations
    this._iri = iri
  }

  get name () {
    return this._name
  }

  get operations () {
    return this._operations
  }

  iri (id) {
    if (this._iri !== null) {
      return this._iri.replace(/\{id\}/, id)
    }

    if (!('get' in this._operations)) {
      throw new Error('could not generate iri')
    }

    return Router.generate(this._operations['get'].route, { id }, false)
  }
}
