import Routing from 'fos-router'
import { shallowRef } from 'vue'
import RouteName from '@/js/metadata/RouteName.js'
import ApiOperation from '@/js/metadata/ApiOperation.js'

export function path (route, params) {
  if (!(route instanceof RouteName)) {
    throw new Error('non route object given')
  }

  if (route instanceof ApiOperation) {
    route = route.route
  }

  return Routing.generate(route.name, params)
}

class LazyPromise extends Promise {
  constructor (executor) {
    super(executor)
    if (typeof executor !== 'function') {
      throw new TypeError('LazyPromise executor is not a function')
    }
    this._executor = executor
  }

  then () {
    this.promise = this.promise || new Promise(this._executor)
    return this.promise.then(...arguments)
  }
}

export class Response {
  headers
  statusCode
  statusMessage
  size
  body
  json
  _streamed
  _isJson
  _response

  constructor (response, streamed = false) {
    const headers = Object.fromEntries(response.headers.entries())
    const size = headers['content-length'] || 0
    const contentType = headers['content-type'] || ''
    const isJson = contentType.includes('application/json')

    this.headers = headers
    this.statusCode = response.statusCode
    this.statusMessage = response.statusMessage
    this.size = size
    this.body = undefined
    this.json = undefined
    this._streamed = streamed
    this._isJson = isJson
    this._response = response
  }

  async resolve () {
    if (this._streamed) {
      this.body = new LazyPromise((resolve) => {
        resolve(this._response.body())
      })
      this.json = new LazyPromise(() => this._response.json())
    } else {
      this.body = await this._response.text()
      this.json = this._isJson ? JSON.parse(this.body) : undefined
    }
  }
}

export class RequestError extends Error {
  headers
  statusCode
  statusMessage
  size
  body
  json
  _response

  constructor (response) {
    super(response.statusMessage)
    this._response = new Response(response)
    this.headers = this._response.headers
    this.statusCode = this._response.statusCode
    this.statusMessage = this._response.statusMessage
    this.size = this._response.size
  }

  async resolve () {
    await this._response.resolve()
    this.body = this._response.body
    this.json = this._response.json
  }
}

function replaceParams (finalUrl, params) {
  const replacedParams = []

  finalUrl = finalUrl.replace(/\{([^}]+)}/g, (match, key) => {
    if (params[key] !== undefined) {
      replacedParams.push(key)
      return encodeURIComponent(params[key])
    }
    throw new Error(`Missing value for URL placeholder: ${key}`)
  })

  let remainingQuery = []
  // Handle leftover params as query arguments
  const remainingParams = Object.keys(params).filter(key => !replacedParams.includes(key))
  if (remainingParams.length > 0) {
    remainingQuery = remainingParams.reduce((acc, key) => {
      acc[key] = params[key]
      return acc
    }, {})
  }
  return {
    url: finalUrl,
    params: remainingQuery
  }
}

function createQueryString (obj, prefix = '') {
  const queryParts = []

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key]
      const indexPrefix = encodeURIComponent(`[${key}]`)
      const newPrefix = prefix ? `${prefix}${indexPrefix}` : encodeURIComponent(key)

      if (typeof value === 'object' && !Array.isArray(value)) {
        queryParts.push(createQueryString(value, newPrefix))
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const indexPrefix = encodeURIComponent(`[${index}]`)
          queryParts.push(createQueryString(item, `${newPrefix}${indexPrefix}`))
        })
      } else {
        queryParts.push(`${newPrefix}=${encodeURIComponent(value)}`)
      }
    }
  }

  return queryParts.join('&')
}

function addQueryToUrl (remainingQuery, finalUrl) {
  finalUrl += (finalUrl.includes('?') ? '&' : '?') + createQueryString(remainingQuery)
  return finalUrl
}

function createUrl (url, route, params, query) {
  let finalUrl = url

  if (typeof route !== 'undefined') {
    finalUrl = path(route, params)
    // Replace placeholders in the URL with params
  } else if (params && typeof params === 'object') {
    const { url: tmpUrl, params: remainingQuery } = replaceParams(url, params)
    finalUrl = tmpUrl

    if (Object.keys(remainingQuery).length > 0) {
      if (query) {
        throw new Error('Cannot use both \'params\' with extra keys and \'query\' option together.')
      }

      finalUrl = addQueryToUrl(remainingQuery, finalUrl)
    }
  }

  // Build query string
  if (query && typeof query === 'object') {
    finalUrl = addQueryToUrl(query, finalUrl)
  }

  try {
    return (new URL(finalUrl)) + ''
  } catch (e) {
    return (new URL(finalUrl, window.location.origin)) + ''
  }
}

function createBody (json, headers, form, body) {
  let finalBody

  // Handle body options
  if (typeof json !== 'undefined') {
    headers.set('Content-Type', 'application/json')
    finalBody = JSON.stringify(json)
  } else if (typeof form !== 'undefined') {
    headers.set('Content-Type', 'application/x-www-form-urlencoded')
    finalBody = new URLSearchParams(form).toString()
  } else if (typeof body !== 'undefined') {
    finalBody = body
  }
  return finalBody
}

function createFetchOptions (method, headers, finalBody, upload) {
  const fetchOptions = {}

  if (typeof headers !== 'undefined') {
    fetchOptions.headers = headers
  }

  if (typeof finalBody !== 'undefined') {
    fetchOptions.body = finalBody
  }

  // Upload progress handling
  if (upload && finalBody instanceof Blob) {
    const totalSize = finalBody.size
    let uploadedSize = 0

    const stream = finalBody.stream().pipeThrough(new TransformStream({
      transform (chunk, controller) {
        uploadedSize += chunk.byteLength
        upload(uploadedSize, totalSize)
        controller.enqueue(chunk)
      }
    }))

    fetchOptions.body = new ReadableStreamDefaultReader(stream)
  }

  if (typeof method === 'undefined') {
    fetchOptions.method = 'GET'
    if (typeof fetchOptions.body !== 'undefined') {
      fetchOptions.method = 'POST'
    }
  } else {
    fetchOptions.method = method
  }

  return fetchOptions
}

/**
 * Simple function to do HTTP requests using the Fetch API.
 *
 * @param {Object|string} options - Configuration options for the request or a string URL.
 * @param {string} options.url - The URL to make the request to. Mandatory.
 * @param {string} options.route - The ROUTE to make the request to. Mandatory.
 * @param {string} options.operation - The Operation to make the request to. Mandatory.
 * @param {string} [options.method='GET'] - The HTTP method to use (e.g., 'GET', 'POST').
 * @param {Object} [options.query] - Key-value pairs to append as query parameters to the URL.
 * @param {Object} [options.params] - Key-value pairs to replace placeholders in the URL (e.g., `{id}`).
 * @param {Object} [options.json] - Data to be sent as a JSON-encoded body. Conflicts with `form` and `body`.
 * @param {Object} [options.form] - Data to be sent as `application/x-www-form-urlencoded` body. Conflicts with `json` and `body`.
 * @param {string} [options.body] - Raw string data to be sent as the body. Conflicts with `json` and `form`.
 * @param {Function} [options.upload] - Callback function for monitoring upload progress. Receives `uploadedSize` and `totalSize` as arguments.
 * @param {boolean} [options.streamed=false] - Whether to return the response as a stream. Defaults to `false`.
 * @param {Object} [options.headers] - Custom headers to include with the request.
 *
 * @returns {Promise<Response>} A promise that resolves to a response object
 * @throws {Error|ResponseError} If the request fails or returns a non-2xx/3xx status code. The error contains the response object.
 */
export function request (options) {
  if (typeof options === 'string') {
    options = { url: options }
  }

  if (!options.url && !options.route && !options.operation) {
    throw new Error("The 'url', 'route' or 'operation' option is mandatory.")
  }

  const { route, operation, url, method, query, params, json, form, body, upload, streamed = false, headers: customHeaders } = options

  if ([url, route, operation].filter(opt => opt !== undefined).length > 1) {
    throw new Error("Only one of 'route', or 'url' can be specified.")
  }

  if ([json, form, body].filter(opt => opt !== undefined).length > 1) {
    throw new Error("Only one of 'json', 'form', or 'body' can be specified.")
  }

  let finalRoute = route
  let finalMethod = method
  let finalParams = params
  if (operation instanceof ApiOperation) {
    finalRoute = operation.route
    finalMethod = operation.method
    finalParams = {
      ...operation.defaults,
      ...params
    }
  }

  const finalUrl = createUrl(url, finalRoute, finalParams, query)
  const headers = new Headers(customHeaders || {})
  const finalBody = createBody(json, headers, form, body)
  const fetchOptions = createFetchOptions(finalMethod, headers, finalBody, upload)

  // Fetch wrapper
  return new Promise((resolve, reject) => {
    fetch(finalUrl, fetchOptions).then(async response => {
      if (!response.ok) {
        const obj = new RequestError(response)
        await obj.resolve()
        reject(obj)
      } else {
        const obj = new Response(response, streamed)
        await obj.resolve()
        resolve(obj)
      }
    }).catch((e) => {
      reject(e)
    })
  })
}

export function useRequest (options, { initialFetch = true } = {}) {
  const response = shallowRef(undefined)
  const error = shallowRef(null)
  const loading = shallowRef(false)
  const oldRequest = shallowRef(null)

  const refresh = () => {
    if (loading.value) {
      return oldRequest.value
    }

    loading.value = true
    try {
      oldRequest.value = new Promise((resolve, reject) => {
        request(options).then(async (r) => {
          response.value = r
          loading.value = false
          resolve(r)
        }).catch((e) => {
          error.value = e
          loading.value = false
          reject(e)
        })
      })
    } catch (e) {
      error.value = e
      loading.value = false
    }

    return oldRequest.value
  }

  if (initialFetch) {
    refresh()
  }

  return {
    response,
    loading,
    error,
    request: oldRequest,
    execute: refresh
  }
}
