import ApiResource from '@/js/metadata/ApiResource.js'
export const Error = new ApiResource(JSON.parse("{\"name\":\"Error\",\"iri\":null,\"operations\":{\"errors\":{\"route\":\"_api_errors\",\"method\":\"GET\"}}}"));
export const ConstraintViolation = new ApiResource(JSON.parse("{\"name\":\"ConstraintViolation\",\"iri\":\"\\\/api\\\/v2\\\/validation_errors\\\/{id}\",\"operations\":{\"validation_errors_problem\":{\"route\":\"_api_validation_errors_problem\",\"method\":\"GET\"},\"validation_errors_hydra\":{\"route\":\"_api_validation_errors_hydra\",\"method\":\"GET\"},\"validation_errors_jsonapi\":{\"route\":\"_api_validation_errors_jsonapi\",\"method\":\"GET\"}}}"));
export const ProjectTask = new ApiResource(JSON.parse("{\"name\":\"ProjectTask\",\"iri\":\"\\\/api\\\/v2\\\/project_tasks\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/project_tasks\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const TaskReceiver = new ApiResource(JSON.parse("{\"name\":\"TaskReceiver\",\"iri\":\"\\\/api\\\/v2\\\/task_receivers\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/task_receivers\\\/{id}{._format}_get\",\"method\":\"GET\"},\"get_collection\":{\"route\":\"_api_\\\/task_receivers{._format}_get_collection\",\"method\":\"GET\"},\"post\":{\"route\":\"_api_\\\/task_receivers{._format}_post\",\"method\":\"POST\"},\"patch\":{\"route\":\"_api_\\\/task_receivers\\\/{id}{._format}_patch\",\"method\":\"PATCH\"},\"delete\":{\"route\":\"_api_\\\/task_receivers\\\/{id}{._format}_delete\",\"method\":\"DELETE\"}}}"));
export const Building = new ApiResource(JSON.parse("{\"name\":\"Building\",\"iri\":\"\\\/api\\\/v2\\\/buildings\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/buildings\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const Unit = new ApiResource(JSON.parse("{\"name\":\"Unit\",\"iri\":\"\\\/api\\\/v2\\\/units\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/units\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const Shortcut = new ApiResource(JSON.parse("{\"name\":\"Shortcut\",\"iri\":\"\\\/api\\\/v2\\\/shortcuts\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/shortcuts\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const Subtopic = new ApiResource(JSON.parse("{\"name\":\"Subtopic\",\"iri\":\"\\\/api\\\/v2\\\/subtopics\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/subtopics\\\/{id}{._format}_get\",\"method\":\"GET\"},\"get_collection\":{\"route\":\"_api_\\\/subtopics{._format}_get_collection\",\"method\":\"GET\"},\"post\":{\"route\":\"_api_\\\/subtopics{._format}_post\",\"method\":\"POST\"},\"patch\":{\"route\":\"_api_\\\/subtopics\\\/{id}{._format}_patch\",\"method\":\"PATCH\"},\"delete\":{\"route\":\"_api_\\\/subtopics\\\/{id}{._format}_delete\",\"method\":\"DELETE\"}}}"));
export const Topic = new ApiResource(JSON.parse("{\"name\":\"Topic\",\"iri\":\"\\\/api\\\/v2\\\/topics\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/topics\\\/{id}{._format}_get\",\"method\":\"GET\"},\"get_collection\":{\"route\":\"_api_\\\/topics{._format}_get_collection\",\"method\":\"GET\"},\"post\":{\"route\":\"_api_\\\/topics{._format}_post\",\"method\":\"POST\"},\"patch\":{\"route\":\"_api_\\\/topics\\\/{id}{._format}_patch\",\"method\":\"PATCH\"},\"delete\":{\"route\":\"_api_\\\/topics\\\/{id}{._format}_delete\",\"method\":\"DELETE\"}}}"));
export const Person = new ApiResource(JSON.parse("{\"name\":\"Person\",\"iri\":\"\\\/api\\\/v2\\\/people\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/people\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const Order = new ApiResource(JSON.parse("{\"name\":\"Order\",\"iri\":\"\\\/api\\\/v2\\\/orders\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/orders\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const Project = new ApiResource(JSON.parse("{\"name\":\"Project\",\"iri\":null,\"operations\":{\"clone_post\":{\"route\":\"_api_\\\/projects\\\/{id}\\\/clone{._format}_post\",\"method\":\"POST\"},\"get\":{\"route\":\"_api_\\\/projects\\\/{order}{._format}_get\",\"method\":\"GET\"}}}"));
export const Service = new ApiResource(JSON.parse("{\"name\":\"Service\",\"iri\":\"\\\/api\\\/v2\\\/services\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/services\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const Group = new ApiResource(JSON.parse("{\"name\":\"Group\",\"iri\":\"\\\/api\\\/v2\\\/groups\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/groups\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const User = new ApiResource(JSON.parse("{\"name\":\"User\",\"iri\":\"\\\/api\\\/v2\\\/users\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/users\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
export const TaskRelationInterface = new ApiResource(JSON.parse("{\"name\":\"TaskRelationInterface\",\"iri\":\"\\\/api\\\/v2\\\/task_relation_interfaces\\\/{id}\",\"operations\":{\"get\":{\"route\":\"_api_\\\/task_relation_interfaces\\\/{id}{._format}_get\",\"method\":\"GET\"}}}"));
