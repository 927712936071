import RouteName from '@/js/metadata/RouteName.js'
export const TV_SIGNAL_REPORT = new RouteName(JSON.parse("{\"name\":\"tv_signal_report\"}"));
export const SHOW_TV_SIGNAL_REPORT = new RouteName(JSON.parse("{\"name\":\"show_tv_signal_report\"}"));
export const BROWSERS_LIST = new RouteName(JSON.parse("{\"name\":\"browsers_list\"}"));
export const CALENDAR_MONTH = new RouteName(JSON.parse("{\"name\":\"calendar_month\"}"));
export const CALENDAR_MONTH_DATE = new RouteName(JSON.parse("{\"name\":\"calendar_month_date\"}"));
export const CALENDAR_WEEK = new RouteName(JSON.parse("{\"name\":\"calendar_week\"}"));
export const CALENDAR_WEEK_DATE = new RouteName(JSON.parse("{\"name\":\"calendar_week_date\"}"));
export const AVAILABILITY_EDIT = new RouteName(JSON.parse("{\"name\":\"availability_edit\"}"));
export const AVAILABILITY_INDEX = new RouteName(JSON.parse("{\"name\":\"availability_index\"}"));
export const CUSTOMER = new RouteName(JSON.parse("{\"name\":\"customer\"}"));
export const HOME = new RouteName(JSON.parse("{\"name\":\"home\"}"));
export const DIAGNOSE_SERVICE = new RouteName(JSON.parse("{\"name\":\"diagnose_service\"}"));
export const DOCUMENT_FILE_DELETE = new RouteName(JSON.parse("{\"name\":\"document_file_delete\"}"));
export const DOCUMENT_DESTROY = new RouteName(JSON.parse("{\"name\":\"document_destroy\"}"));
export const DOCUMENT_DOWNLOAD = new RouteName(JSON.parse("{\"name\":\"document_download\"}"));
export const DOCUMENT_SHOW_CONTENT = new RouteName(JSON.parse("{\"name\":\"document_show_content\"}"));
export const DOCUMENT_INDEX = new RouteName(JSON.parse("{\"name\":\"document_index\"}"));
export const DOCUMENT_SHOW_THUMBNAIL = new RouteName(JSON.parse("{\"name\":\"document_show_thumbnail\"}"));
export const DSLAM_ADD_SERVICED = new RouteName(JSON.parse("{\"name\":\"dslam_add_serviced\"}"));
export const DSLAM_CLEAR_ERRORS = new RouteName(JSON.parse("{\"name\":\"dslam_clear_errors\"}"));
export const DSLAM_ADD = new RouteName(JSON.parse("{\"name\":\"dslam_add\"}"));
export const DSLAM_DELETE = new RouteName(JSON.parse("{\"name\":\"dslam_delete\"}"));
export const DSLAM_DELETE_CONFIRMED = new RouteName(JSON.parse("{\"name\":\"dslam_delete_confirmed\"}"));
export const DSLAM_DETAIL = new RouteName(JSON.parse("{\"name\":\"dslam_detail\"}"));
export const DSLAM_FETCHDSLAMPORTS = new RouteName(JSON.parse("{\"name\":\"dslam_fetchdslamports\"}"));
export const DSLAM_LIST = new RouteName(JSON.parse("{\"name\":\"dslam_list\"}"));
export const DSLAM_REINIT = new RouteName(JSON.parse("{\"name\":\"dslam_reinit\"}"));
export const DSLAM_REMOVE_SERVICED = new RouteName(JSON.parse("{\"name\":\"dslam_remove_serviced\"}"));
export const DSLAM_REMOVE_SERVICED_CONFIRM = new RouteName(JSON.parse("{\"name\":\"dslam_remove_serviced_confirm\"}"));
export const DSLAM_TOGGLE_SUPERVECTORING_EXCLUSION = new RouteName(JSON.parse("{\"name\":\"dslam_toggle_supervectoring_exclusion\"}"));
export const EXPORT_BUILDINGS = new RouteName(JSON.parse("{\"name\":\"export_buildings\"}"));
export const EXPORT_PROJECTS = new RouteName(JSON.parse("{\"name\":\"export_projects\"}"));
export const ONT_ASSIGN_PERSON = new RouteName(JSON.parse("{\"name\":\"ont_assign_person\"}"));
export const ONT_ASSIGN_UNIT = new RouteName(JSON.parse("{\"name\":\"ont_assign_unit\"}"));
export const ONT_EDIT = new RouteName(JSON.parse("{\"name\":\"ont_edit\"}"));
export const ONT_FORCE_SYNC = new RouteName(JSON.parse("{\"name\":\"ont_force_sync\"}"));
export const ONT_LIST = new RouteName(JSON.parse("{\"name\":\"ont_list\"}"));
export const PERSON_COMBINE = new RouteName(JSON.parse("{\"name\":\"person_combine\"}"));
export const PERSON_COMBINE_CONFIRM = new RouteName(JSON.parse("{\"name\":\"person_combine_confirm\"}"));
export const PERSON_MOVE_CONFIRM = new RouteName(JSON.parse("{\"name\":\"person_move_confirm\"}"));
export const PERSON_NEW = new RouteName(JSON.parse("{\"name\":\"person_new\"}"));
export const PERSON_NEW_CONTRACT_PARTNER = new RouteName(JSON.parse("{\"name\":\"person_new_contract_partner\"}"));
export const PERSON_DELETE = new RouteName(JSON.parse("{\"name\":\"person_delete\"}"));
export const PERSON_DELETE_CONFIRM = new RouteName(JSON.parse("{\"name\":\"person_delete_confirm\"}"));
export const PERSON = new RouteName(JSON.parse("{\"name\":\"person\"}"));
export const PERSON_MOVE = new RouteName(JSON.parse("{\"name\":\"person_move\"}"));
export const PERSON_SAVED_CONFIRM = new RouteName(JSON.parse("{\"name\":\"person_saved_confirm\"}"));
export const PING = new RouteName(JSON.parse("{\"name\":\"ping\"}"));
export const APP_LOGIN = new RouteName(JSON.parse("{\"name\":\"app_login\"}"));
export const APP_LOGOUT = new RouteName(JSON.parse("{\"name\":\"app_logout\"}"));
export const SERVICE_ADD = new RouteName(JSON.parse("{\"name\":\"service_add\"}"));
export const SERVICE_DELETE = new RouteName(JSON.parse("{\"name\":\"service_delete\"}"));
export const SERVICE_EDIT = new RouteName(JSON.parse("{\"name\":\"service_edit\"}"));
export const SERVICE_OVERVIEW = new RouteName(JSON.parse("{\"name\":\"service_overview\"}"));
export const CARD_ADD_PORT = new RouteName(JSON.parse("{\"name\":\"card_add_port\"}"));
export const SWITCH_ADD = new RouteName(JSON.parse("{\"name\":\"switch_add\"}"));
export const SWITCH_ADD_CARD = new RouteName(JSON.parse("{\"name\":\"switch_add_card\"}"));
export const SWITCH_CARD_DETAILS = new RouteName(JSON.parse("{\"name\":\"switch_card_details\"}"));
export const SWITCH_CARD_DELETE = new RouteName(JSON.parse("{\"name\":\"switch_card_delete\"}"));
export const SWITCH_DETAIL = new RouteName(JSON.parse("{\"name\":\"switch_detail\"}"));
export const SWITCH_DUMP_CARD_CONFIG = new RouteName(JSON.parse("{\"name\":\"switch_dump_card_config\"}"));
export const SWITCH_EDIT = new RouteName(JSON.parse("{\"name\":\"switch_edit\"}"));
export const SWITCH_LIST = new RouteName(JSON.parse("{\"name\":\"switch_list\"}"));
export const SWITCH_PORT_TOGGLE_RESERVED = new RouteName(JSON.parse("{\"name\":\"switch_port_toggle_reserved\"}"));
export const EVALUATION_INDEX = new RouteName(JSON.parse("{\"name\":\"evaluation_index\"}"));
export const TASK_DELETE = new RouteName(JSON.parse("{\"name\":\"task_delete\"}"));
export const TASK_DELETE_DOCUMENT = new RouteName(JSON.parse("{\"name\":\"task_delete_document\"}"));
export const AUFGABE = new RouteName(JSON.parse("{\"name\":\"aufgabe\"}"));
export const AUFGABEN_EXTENDED = new RouteName(JSON.parse("{\"name\":\"aufgaben_extended\"}"));
export const AUFGABEN = new RouteName(JSON.parse("{\"name\":\"aufgaben\"}"));
export const AUFGABE_NEW = new RouteName(JSON.parse("{\"name\":\"aufgabe_new\"}"));
export const USER_SETTINGS_ADD_FORWARDING = new RouteName(JSON.parse("{\"name\":\"user_settings_add_forwarding\"}"));
export const USER_SETTINGS_DELETE_CONFIRM = new RouteName(JSON.parse("{\"name\":\"user_settings_delete_confirm\"}"));
export const USER_SETTINGS_REMOVE_FORWARDING = new RouteName(JSON.parse("{\"name\":\"user_settings_remove_forwarding\"}"));
export const USER_SETTINGS_DELETE = new RouteName(JSON.parse("{\"name\":\"user_settings_delete\"}"));
export const USER_SETTINGS_EDIT_FORWARDING = new RouteName(JSON.parse("{\"name\":\"user_settings_edit_forwarding\"}"));
export const USER_SETTINGS_HOME = new RouteName(JSON.parse("{\"name\":\"user_settings_home\"}"));
export const USER_SETTINGS_HOME_ADMIN = new RouteName(JSON.parse("{\"name\":\"user_settings_home_admin\"}"));
export const ACCESS_RIGHTS_INDEX = new RouteName(JSON.parse("{\"name\":\"access_rights_index\"}"));
export const ACCESS_RIGHTS_PRIVILEGE = new RouteName(JSON.parse("{\"name\":\"access_rights_privilege\"}"));
export const ACCESS_RIGHTS_TOPIC = new RouteName(JSON.parse("{\"name\":\"access_rights_topic\"}"));
export const DELETE_GROUP_CONFIRM = new RouteName(JSON.parse("{\"name\":\"delete_group_confirm\"}"));
export const DELETE_GROUP_MEMBER_CONFIRM = new RouteName(JSON.parse("{\"name\":\"delete_group_member_confirm\"}"));
export const USER_GROUP_CREATE = new RouteName(JSON.parse("{\"name\":\"user_group_create\"}"));
export const ADMIN_USER_DEFAULT = new RouteName(JSON.parse("{\"name\":\"admin_user_default\"}"));
export const DELETE_GROUP = new RouteName(JSON.parse("{\"name\":\"delete_group\"}"));
export const DELETE_GROUP_MEMBER = new RouteName(JSON.parse("{\"name\":\"delete_group_member\"}"));
export const USER_GROUP_EDIT = new RouteName(JSON.parse("{\"name\":\"user_group_edit\"}"));
export const SHOW_EDIT_PRIVILEGES = new RouteName(JSON.parse("{\"name\":\"show_edit_privileges\"}"));
export const SHOW_GROUP = new RouteName(JSON.parse("{\"name\":\"show_group\"}"));
export const ADMIN_INDEX_DEFAULT = new RouteName(JSON.parse("{\"name\":\"admin_index_default\"}"));
export const PRIVILEGE_EDIT = new RouteName(JSON.parse("{\"name\":\"privilege_edit\"}"));
export const PRIVILEGE_LIST = new RouteName(JSON.parse("{\"name\":\"privilege_list\"}"));
export const PRODUCT_CREATE = new RouteName(JSON.parse("{\"name\":\"product_create\"}"));
export const PRODUCT_EDIT = new RouteName(JSON.parse("{\"name\":\"product_edit\"}"));
export const PRODUCT_LIST = new RouteName(JSON.parse("{\"name\":\"product_list\"}"));
export const PROJECT_TYPE_EDIT = new RouteName(JSON.parse("{\"name\":\"project_type_edit\"}"));
export const PROJECT_TYPES_LIST = new RouteName(JSON.parse("{\"name\":\"project_types_list\"}"));
export const SHORTCUT_CREATE = new RouteName(JSON.parse("{\"name\":\"shortcut_create\"}"));
export const SHORTCUT_DELETE = new RouteName(JSON.parse("{\"name\":\"shortcut_delete\"}"));
export const SHORTCUT_EDIT = new RouteName(JSON.parse("{\"name\":\"shortcut_edit\"}"));
export const SHORTCUT_LIST = new RouteName(JSON.parse("{\"name\":\"shortcut_list\"}"));
export const SUBTOPIC_CREATE = new RouteName(JSON.parse("{\"name\":\"subtopic_create\"}"));
export const SUBTOPIC_EDIT = new RouteName(JSON.parse("{\"name\":\"subtopic_edit\"}"));
export const SUBTOPIC_LIST = new RouteName(JSON.parse("{\"name\":\"subtopic_list\"}"));
export const TOPIC_CREATE = new RouteName(JSON.parse("{\"name\":\"topic_create\"}"));
export const TOPIC_EDIT = new RouteName(JSON.parse("{\"name\":\"topic_edit\"}"));
export const TOPIC_LIST = new RouteName(JSON.parse("{\"name\":\"topic_list\"}"));
export const USER_CREATE = new RouteName(JSON.parse("{\"name\":\"user_create\"}"));
export const API_V1_SPRI_EINZELVERTRAGNUMMER = new RouteName(JSON.parse("{\"name\":\"api_v1_spri_einzelvertragnummer\"}"));
export const API_V1_SPRI_LINEID = new RouteName(JSON.parse("{\"name\":\"api_v1_spri_lineid\"}"));
export const API_V1_SPRI_AUFTRAG_NEW = new RouteName(JSON.parse("{\"name\":\"api_v1_spri_auftrag_new\"}"));
export const API_V1_SPRI_AUFTRAG_NEW_SLASH = new RouteName(JSON.parse("{\"name\":\"api_v1_spri_auftrag_new_slash\"}"));
export const API_V1_BUILDING_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_building_get\"}"));
export const API_V1_BUILDING_INFO_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_building_info_get\"}"));
export const API_V1_BUILDING_SEARCH = new RouteName(JSON.parse("{\"name\":\"api_v1_building_search\"}"));
export const API_V1_BUILDING_SEARCH_SUPPLIED_BY = new RouteName(JSON.parse("{\"name\":\"api_v1_building_search_supplied_by\"}"));
export const API_V1_BUILDING_GET_ONTS = new RouteName(JSON.parse("{\"name\":\"api_v1_building_get_onts\"}"));
export const API_V1_DETECON_DIFF_STREAM = new RouteName(JSON.parse("{\"name\":\"api_v1_detecon_diff_stream\"}"));
export const API_V1_DETECON_MASTER_STREAM = new RouteName(JSON.parse("{\"name\":\"api_v1_detecon_master_stream\"}"));
export const API_V1_DOCUMENT_GET_PROJECT = new RouteName(JSON.parse("{\"name\":\"api_v1_document_get_project\"}"));
export const API_V1_DOCUMENT_LIST = new RouteName(JSON.parse("{\"name\":\"api_v1_document_list\"}"));
export const API_V1_DOCUMENT_CREATE = new RouteName(JSON.parse("{\"name\":\"api_v1_document_create\"}"));
export const API_V1_DOCUMENT_DELETE = new RouteName(JSON.parse("{\"name\":\"api_v1_document_delete\"}"));
export const API_V1_DOCUMENT_POST_PROJECT = new RouteName(JSON.parse("{\"name\":\"api_v1_document_post_project\"}"));
export const API_V1_PERSON_COUNT = new RouteName(JSON.parse("{\"name\":\"api_v1_person_count\"}"));
export const API_V1_PERSON_UPDATE = new RouteName(JSON.parse("{\"name\":\"api_v1_person_update\"}"));
export const API_V1_PERSON_SEARCH = new RouteName(JSON.parse("{\"name\":\"api_v1_person_search\"}"));
export const API_V1_PERSON_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_person_get\"}"));
export const API_V1_TASK_SEARCH = new RouteName(JSON.parse("{\"name\":\"api_v1_task_search\"}"));
export const API_V1_TOPIC_LIST_TREE = new RouteName(JSON.parse("{\"name\":\"api_v1_topic_list_tree\"}"));
export const API_V1_UNIT_UPDATE = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_update\"}"));
export const API_V1_AVATAR_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_user_avatar_get\"}"));
export const API_V1_AVATAR_PUT = new RouteName(JSON.parse("{\"name\":\"api_v1_user_avatar_put\"}"));
export const API_V1_AVATAR_DELETE = new RouteName(JSON.parse("{\"name\":\"api_v1_user_avatar_delete\"}"));
export const API_V1_UNIT_LIST = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_list\"}"));
export const API_V1_UNIT_ADD = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_add\"}"));
export const API_V1_UNIT_DELETE = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_delete\"}"));
export const API_V1_UNIT_SERVICES_LIST = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_service_list\"}"));
export const API_V1_UNIT_VERIFY = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_verify\"}"));
export const API_V1_UNIT_REUPLOAD_LABEL = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_reupload_label\"}"));
export const API_V1_UNIT_LOCK = new RouteName(JSON.parse("{\"name\":\"api_v1_unit_lock\"}"));
export const API_V1_USER_PRIVILEGE_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_user_privilege_get\"}"));
export const API_V1_USER_PRIVILEGE_LIST = new RouteName(JSON.parse("{\"name\":\"api_v1_user_privilege_list\"}"));
export const API_V1_AVAILABILITY_STREET_SEARCH = new RouteName(JSON.parse("{\"name\":\"api_v1_availability_street_search\"}"));
export const API_V1_AVAILABILITY_BUILDING_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_availability_building_get\"}"));
export const API_V1_PRIVILEGE_HISTORY_USER_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_user_get\"}"));
export const API_V1_PRIVILEGE_HISTORY_TOPIC_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_topic_get\"}"));
export const API_V1_PRIVILEGE_HISTORY_TOPIC_ADD = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_topic_add\"}"));
export const API_V1_PRIVILEGE_HISTORY_SUBTOPIC_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_subtopic_get\"}"));
export const API_V1_PRIVILEGE_HISTORY_READWRITE = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_readwrite\"}"));
export const API_V1_PRIVILEGE_HISTORY_ALL = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_all\"}"));
export const API_V1_PRIVILEGE_HISTORY_SUBTOPIC_ADD = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_subtopic_add\"}"));
export const API_V1_PRIVILEGE_HISTORY_REMOVE = new RouteName(JSON.parse("{\"name\":\"api_v1_privilege_history_remove\"}"));
export const API_V1_PROJECT_CALCULATION_CONCEPT = new RouteName(JSON.parse("{\"name\":\"api_v1_project_calculation_concept\"}"));
export const API_V1_PROJECT_CONCEPT_ADDITIONAL_INFO_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_project_concept_additional_info_get\"}"));
export const API_V1_PROJECT_CONCEPT_PRESELECTION_DELETE = new RouteName(JSON.parse("{\"name\":\"api_v1_project_concept_preselection_delete\"}"));
export const API_V1_PROJECT_CONCEPT_PRESELECTION_SAVE = new RouteName(JSON.parse("{\"name\":\"api_v1_project_concept_preselection_save\"}"));
export const API_V1_PROJECT_CONCEPT_PRESELECTION_GET = new RouteName(JSON.parse("{\"name\":\"api_v1_project_concept_preselection_get\"}"));
export const BUILDING_DELETE_DOCUMENT = new RouteName(JSON.parse("{\"name\":\"building_delete_document\"}"));
export const BUILDING_FLATS = new RouteName(JSON.parse("{\"name\":\"building_flats\"}"));
export const BUILDING_DETAILS_CONFIRM = new RouteName(JSON.parse("{\"name\":\"building_details_confirm\"}"));
export const FASERMGMT = new RouteName(JSON.parse("{\"name\":\"fasermgmt\"}"));
export const BUILDING_HISTORY = new RouteName(JSON.parse("{\"name\":\"building_history\"}"));
export const BUILDING_DETAILS = new RouteName(JSON.parse("{\"name\":\"building_details\"}"));
export const BUILDING_PERSON_SEARCH = new RouteName(JSON.parse("{\"name\":\"building_person_search\"}"));
export const BUILDING_SEARCH = new RouteName(JSON.parse("{\"name\":\"building_search\"}"));
export const BUILDING_INDEX = new RouteName(JSON.parse("{\"name\":\"building_index\"}"));
export const BUILDING_INDEX_EDIT_NEWS = new RouteName(JSON.parse("{\"name\":\"building_index_edit_news\"}"));
export const BUILDING_LIST = new RouteName(JSON.parse("{\"name\":\"building_list\"}"));
export const BUILDING_SET_SUPPLIED_BY = new RouteName(JSON.parse("{\"name\":\"building_set_supplied_by\"}"));
export const UNIT_EDIT = new RouteName(JSON.parse("{\"name\":\"unit_edit\"}"));
export const UNIT_DETAIL = new RouteName(JSON.parse("{\"name\":\"unit_detail\"}"));
export const ADDRESSBOOK_LIST = new RouteName(JSON.parse("{\"name\":\"addressbook_list\"}"));
export const PROJECT_CALCULATION_EDIT = new RouteName(JSON.parse("{\"name\":\"project_calculation_edit\"}"));
export const CALCULATION_EDIT = new RouteName(JSON.parse("{\"name\":\"calculation_edit\"}"));
export const CONCEPT_COPY = new RouteName(JSON.parse("{\"name\":\"concept_copy\"}"));
export const PROJECT_CONCEPT_NEW = new RouteName(JSON.parse("{\"name\":\"project_concept_new\"}"));
export const CONCEPT_DOCUMENT_DELETE = new RouteName(JSON.parse("{\"name\":\"concept_document_delete\"}"));
export const CONCEPT_EDIT = new RouteName(JSON.parse("{\"name\":\"concept_edit\"}"));
export const PROJECT_CONCEPT_EDIT = new RouteName(JSON.parse("{\"name\":\"project_concept_edit\"}"));
export const CONCEPT_INFRASTRUCTURE_EDIT = new RouteName(JSON.parse("{\"name\":\"concept_infrastructure_edit\"}"));
export const CONCEPT_INHOUSE_EDIT = new RouteName(JSON.parse("{\"name\":\"concept_inhouse_edit\"}"));
export const CONCEPT_PRESERVICE_EDIT = new RouteName(JSON.parse("{\"name\":\"concept_preservice_edit\"}"));
export const CONCEPT_RT_EDIT = new RouteName(JSON.parse("{\"name\":\"concept_rt_edit\"}"));
export const CONCEPT_JSON = new RouteName(JSON.parse("{\"name\":\"concept_json\"}"));
export const PROJECT_FIBER_PATH_NEW = new RouteName(JSON.parse("{\"name\":\"project_fiber_path_new\"}"));
export const FIBER_PATH_DELETE = new RouteName(JSON.parse("{\"name\":\"fiber_path_delete\"}"));
export const FIBER_PATH_DELETE_CONFIRM = new RouteName(JSON.parse("{\"name\":\"fiber_path_delete_confirm\"}"));
export const FIBER_PATH_EDIT = new RouteName(JSON.parse("{\"name\":\"fiber_path_edit\"}"));
export const PROJECT_FIBER_PATH = new RouteName(JSON.parse("{\"name\":\"project_fiber_path\"}"));
export const PROJECT_BUILDING_ADD = new RouteName(JSON.parse("{\"name\":\"project_building_add\"}"));
export const PROJECT_CUSTOMER_ADD = new RouteName(JSON.parse("{\"name\":\"project_customer_add\"}"));
export const PROJECT_INVOICERECIPIENT_ADD = new RouteName(JSON.parse("{\"name\":\"project_invoiceRecipient_add\"}"));
export const PROJECT_PARTNER_ADD = new RouteName(JSON.parse("{\"name\":\"project_partner_add\"}"));
export const PROJECT_NEW = new RouteName(JSON.parse("{\"name\":\"project_new\"}"));
export const PROJECT_BUILDING_DELETE = new RouteName(JSON.parse("{\"name\":\"project_building_delete\"}"));
export const PROJECT_EDIT = new RouteName(JSON.parse("{\"name\":\"project_edit\"}"));
export const PROJECT_LIST = new RouteName(JSON.parse("{\"name\":\"project_list\"}"));
export const PROJECT_EXPORT = new RouteName(JSON.parse("{\"name\":\"project_export\"}"));
export const PROJECT_CUSTOMER_DELETE = new RouteName(JSON.parse("{\"name\":\"project_customer_delete\"}"));
export const PROJECT_LOCATION = new RouteName(JSON.parse("{\"name\":\"project_location\"}"));
export const PROJECT_SUMMARY = new RouteName(JSON.parse("{\"name\":\"project_summary\"}"));
export const PROJECT_TYPE_PRODUCTS_JSON = new RouteName(JSON.parse("{\"name\":\"project_type_products_json\"}"));
export const CALCULATION_OFFER_EDIT = new RouteName(JSON.parse("{\"name\":\"calculation_offer_edit\"}"));
export const OFFER_EDIT = new RouteName(JSON.parse("{\"name\":\"offer_edit\"}"));
export const PROJECT_OFFER = new RouteName(JSON.parse("{\"name\":\"project_offer\"}"));
export const ORDER_TIMELINE_EDIT = new RouteName(JSON.parse("{\"name\":\"order_timeline_edit\"}"));
export const PROJECT_TIMELINE_INDEX = new RouteName(JSON.parse("{\"name\":\"project_timeline_index\"}"));
export const REPORT_ADDRESSES_EXPORT = new RouteName(JSON.parse("{\"name\":\"report_addresses_export\"}"));
export const REPORT_ADDRESSES = new RouteName(JSON.parse("{\"name\":\"report_addresses\"}"));
export const APL_ANALYSIS = new RouteName(JSON.parse("{\"name\":\"apl_analysis\"}"));
export const BANDWIDTH_EXPORT = new RouteName(JSON.parse("{\"name\":\"bandwidth_export\"}"));
export const BANDWIDTH_REPORT = new RouteName(JSON.parse("{\"name\":\"bandwidth_report\"}"));
export const EXPORT_PEOPLE_BY_BANDWIDTH = new RouteName(JSON.parse("{\"name\":\"export_people_by_bandwidth\"}"));
export const SHOW_PEOPLE_BY_BANDWIDTH = new RouteName(JSON.parse("{\"name\":\"show_people_by_bandwidth\"}"));
export const BUILDING_EXPANSION_TYPE_REPORT = new RouteName(JSON.parse("{\"name\":\"building_expansion_type_report\"}"));
export const DETECON_SYNC_SESSION_LOG_DELETE = new RouteName(JSON.parse("{\"name\":\"detecon_sync_session_log_delete\"}"));
export const DETECON_SYNC_SESSION = new RouteName(JSON.parse("{\"name\":\"detecon_sync_session\"}"));
export const DSLAM_REPORT = new RouteName(JSON.parse("{\"name\":\"dslam_report\"}"));
export const REPORT_INDEX = new RouteName(JSON.parse("{\"name\":\"report_index\"}"));
export const REPLICA_DOWNLOAD = new RouteName(JSON.parse("{\"name\":\"replica_download\"}"));
export const REPLICA_REPORT_INDEX = new RouteName(JSON.parse("{\"name\":\"replica_report_index\"}"));
export const POP_MAP = new RouteName(JSON.parse("{\"name\":\"pop_map\"}"));
export const POP_GEO_JSON = new RouteName(JSON.parse("{\"name\":\"pop_geo_json\"}"));
export const CLUSTER_GEO_JSON = new RouteName(JSON.parse("{\"name\":\"cluster_geo_json\"}"));
export const BUILDING_GEO_JSON = new RouteName(JSON.parse("{\"name\":\"building_geo_json\"}"));
export const DOCUMENT_TAG_LIST = new RouteName(JSON.parse("{\"name\":\"document_tag_list\"}"));
export const DOCUMENT_TAG_EDIT = new RouteName(JSON.parse("{\"name\":\"document_tag_edit\"}"));
export const DOCUMENT_TAG_CREATE = new RouteName(JSON.parse("{\"name\":\"document_tag_create\"}"));
export const DOCUMENT_TAG_DELETE = new RouteName(JSON.parse("{\"name\":\"document_tag_delete\"}"));
export const API_GENID = new RouteName(JSON.parse("{\"name\":\"api_genid\"}"));
export const API_ERRORS = new RouteName(JSON.parse("{\"name\":\"api_errors\"}"));
export const API_VALIDATION_ERRORS = new RouteName(JSON.parse("{\"name\":\"api_validation_errors\"}"));
export const API_ENTRYPOINT = new RouteName(JSON.parse("{\"name\":\"api_entrypoint\"}"));
export const API_DOC = new RouteName(JSON.parse("{\"name\":\"api_doc\"}"));
export const API_GRAPHQL_ENTRYPOINT = new RouteName(JSON.parse("{\"name\":\"api_graphql_entrypoint\"}"));
export const API_GRAPHQL_GRAPHIQL = new RouteName(JSON.parse("{\"name\":\"api_graphql_graphiql\"}"));
export const API_JSONLD_CONTEXT = new RouteName(JSON.parse("{\"name\":\"api_jsonld_context\"}"));
