export default class RouteName {
  _name = null
  constructor ({ name }) {
    this._name = name
  }

  get name () {
    return this._name
  }
}
